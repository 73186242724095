'use client';

import { MDXClient, type SerializeResult } from 'next-mdx-remote-client';
import { serialize } from 'next-mdx-remote-client/serialize';
import { FC, useEffect, useState } from 'react';
import { MDXComponents } from './MDXComponents';

const MDXOnClient: FC<{
	content?: string;
}> = ({ content }) => {
	const [isOnClient, setIsOnClient] = useState(false);
	const [serializedSource, setSerializedSource] =
		useState<SerializeResult | null>(null);

	useEffect(() => {
		setIsOnClient(typeof window !== 'undefined');

		if (!content) {
			setSerializedSource(null);
			return;
		}

		const getAndSerializeSource = async () =>
			await serialize({
				source: content,
			});

		getAndSerializeSource().then((data) => setSerializedSource(data));
	}, [content]);

	if (!serializedSource || !isOnClient || 'error' in serializedSource)
		return null;

	return <MDXClient {...serializedSource} components={MDXComponents} />;
};

export default MDXOnClient;
