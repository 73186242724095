import { MDXComponents as MDXComponentsType } from 'mdx/types';
import Link from 'next/link';
import { ReactNode } from 'react';
import AltDecisionTree from '../AltDecisionTree/AltDecisionTree';
import EmojiButton from '../Button/EmojiButton';
import EmojiText from '../EmojiText/EmojiText';
import NewsOverview from '../NewsOverview/NewsOverview';
import CheckList from './CheckList';
import H1 from './H1';
import Iframe from './Iframe';
import Image from './Image';
import MultipleChoice from './MultipleChoice';
import PersonasCard from './Personas';
import SingleTip from './SingleTip';

const TextElementTags = ['h2', 'h3', 'h4', 'h5', 'h6', 'p'] as const;

type TextElementTag = (typeof TextElementTags)[number];

export const MDXComponents: MDXComponentsType = {
	Image: Image,
	Iframe: Iframe,
	SingleTip: SingleTip,
	MultipleChoice: MultipleChoice,
	NewsOverview: NewsOverview,
	AltDecisionTree: AltDecisionTree,
	Personas: PersonasCard,
	CheckList: CheckList,
	EmojiButton: EmojiButton,
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	a: ({ href, children }: { href?: string; children?: ReactNode }) => (
		<Link
			href={href ?? ''}
			target={
				href?.startsWith('http') || href?.startsWith('/attachments/')
					? '_blank'
					: undefined
			}
		>
			{children}
		</Link>
	),
	// Add all text elements to support emoji
	h1: H1,
	...TextElementTags.reduce(
		(acc, tag) => {
			acc[tag] = ({ children }: { children?: ReactNode | string }) => (
				<TextElement tag={tag}>{children}</TextElement>
			);
			return acc;
		},
		{} as Record<
			TextElementTag,
			({ children }: { children?: ReactNode | string }) => JSX.Element
		>,
	),
};

const TextElement = ({
	tag,
	children,
}: {
	tag: TextElementTag;
	children?: ReactNode;
}) => {
	// Create an element with the tag name
	const Element = tag;
	return (
		<Element>
			<EmojiText>{children}</EmojiText>
		</Element>
	);
};
